<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">
            <template v-for="(item, key) in list_check_box"> 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    
                    <!-- Section Detail Start -->
                    <!--information_general start-->
                    <InformationGeneral v-if="key == 'detail_information_general' && item.show" />
                    <!--information_general end-->

                    <!-- Summary Start -->
                    <div v-else-if="key == 'detail_summary' && item.show">
                        <table class="comparateur2 border-top-gray" >
                            <thead>
                                <tr>
                                    <th colspan="2">{{$t('ceesp.Appreciation')}}</th>
                                    <th> {{$t('ceesp.reverse')}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, key) in data.ceesp.ceesp_titleappreserve" :key="'ceesp_titleappreserve_'+key">
                                    <td width="20%">
                                        <template v-if="$i18n.locale == 'fr'">{{item['titlefr']}}</template>
                                        <template v-else>{{item['titleen']}}</template>
                                    </td>
                                    <td width="20%">
                                        {{$t(item['appreciation'])}}
                                    </td>
                                    <td width="60%">
                                        <span v-if="$i18n.locale == 'fr'" v-html="item['reversefr']"></span>
                                        <span v-else v-html="item['reverseen']"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- Summary Start -->

                    <!-- EconomicAnalysi start -->
                    <table class="comparateur2" v-else-if="key == 'detail_EconomicAnalysis' && item.show" >
                        <thead>  
                            <tr>
                                <th width="15%">{{$t('type_of_economic_analysis')}}</th>
                                <th width="15%" v-if="not_empty_row['source_of_analysis']">{{$t('source_of_analysis')}}</th>
                                <th width="15%" v-if="not_empty_row['patient_population']">{{$t('patient_population')}}</th>
                                <th width="15%" v-if="not_empty_row['comparator']">{{$t('comparator')}}</th>
                                <th width="15%" v-if="not_empty_row['icer_gbp']">{{$t('icer_gbp')}} (CAD)</th>
                                <th width="15%" v-if="not_empty_row['icer_range_gbp']">{{$t('icer_range_gbp')}} (CAD)</th>
                                <th width="15%" v-if="not_empty_row['icur_gbp']">{{$t('icur_gbp')}} (CAD)</th>
                                <th width="15%" v-if="not_empty_row['icur_range_gbp']">{{$t('icur_range_gbp')}} (CAD)</th>
                                <th width="15%" v-if="not_empty_row['cost_savings_gbp']">{{$t('cost_savings_gbp')}} (CAD)</th>
                                <th width="15%" v-if="not_empty_row['total_cost_gbp']">{{$t('total_cost_gbp')}} (CAD)</th>
                                <th width="15%" v-if="not_empty_row['net_monetary_benefit_gbp']">{{$t('net_monetary_benefit_gbp')}} (CAD)</th>
                                <th width="15%" v-if="not_empty_row['net_monetary_loss_gbp']">{{$t('net_monetary_loss_gbp')}} (CAD)</th>
                                <th width="25%" colspan="2" v-if="not_empty_row['smc_comment']">{{$t('comment')}}</th>
                            </tr>
                        </thead> 
                        <tbody> 
                            <template v-for="(items, key) in data.ceesp.evaluation_economic">
                                <tr v-for="(item, key2) in items.evaluation_economic_economic_analysis" :key="key+'_'+key2">
                                    <td>{{$t(items.type_of_economic_analysis)}}</td>
                                    <td v-if="not_empty_row['source_of_analysis']">
                                        <template v-if="$i18n.locale == 'fr'">
                                            {{item.source_of_analysis ? item.source_of_analysis : '-'}}
                                        </template>
                                        <template v-else>
                                            {{item.source_of_analysis_en ? item.source_of_analysis_en : '-'}}
                                        </template>
                                    </td>
                                    <td v-if="not_empty_row['patient_population']">
                                        <template v-if="$i18n.locale == 'fr'">
                                            {{item.patient_population ? item.patient_population : '-'}}
                                        </template>
                                        <template v-else>
                                            {{item.patient_population_en ? item.patient_population_en : '-'}}
                                        </template>
                                    </td>
                                    <td v-if="not_empty_row['comparator']">
                                        <template v-if="$i18n.locale == 'fr'">
                                            {{item.comparator ? item.comparator : '-'}}
                                        </template>
                                        <template v-else>
                                            {{item.comparator_en ? item.comparator_en : '-'}}
                                        </template>
                                    </td>
                                    <td v-if="not_empty_row['icer_gbp']">
                                        <template v-if="$i18n.locale == 'fr'">
                                            {{item.icer_gbp}} {{item.icer_type}}
                                        </template>
                                        <template v-else>
                                            {{item.icer_gbp_en}} {{item.icer_type_en}}
                                        </template>
                                    </td>
                                    <td v-if="not_empty_row['icer_range_gbp']">
                                        <template v-if="$i18n.locale == 'fr'">
                                            {{item.icer_range_gbp}} {{item.icer_type}}
                                        </template>
                                        <template v-else>
                                            {{item.icer_range_gbp_en}} {{item.icer_type_en}}
                                        </template>
                                    </td>
                                    <td v-if="not_empty_row['icur_gbp']">
                                        <template v-if="$i18n.locale == 'fr'">
                                            {{item.icur_gbp ? item.icur_gbp : '-'}}
                                        </template>
                                        <template v-else>
                                            {{item.icur_gbp_en ? item.icur_gbp_en : '-'}}
                                        </template>
                                    </td>
                                    <td v-if="not_empty_row['icur_range_gbp']">
                                        <template v-if="$i18n.locale == 'fr'">
                                            {{item.icur_range_gbp ? item.icur_range_gbp : '-'}}
                                        </template>
                                        <template v-else>
                                            {{item.icur_range_gbp_en ? item.icur_range_gbp_en : '-'}}
                                        </template>
                                    </td>
                                    <td v-if="not_empty_row['cost_savings_gbp']">
                                        <template v-if="$i18n.locale == 'fr'">
                                            {{item.cost_savings_gbp ? item.cost_savings_gbp : '-'}}
                                        </template>
                                        <template v-else>
                                            {{item.cost_savings_gbp_en ? item.cost_savings_gbp_en : '-'}}
                                        </template>
                                    </td>
                                    <td v-if="not_empty_row['total_cost_gbp']">
                                        <template v-if="$i18n.locale == 'fr'">
                                            {{item.total_cost_gbp ? item.total_cost_gbp : '-'}}
                                        </template>
                                        <template v-else>
                                            {{item.total_cost_gbp_en ? item.total_cost_gbp_en : '-'}}
                                        </template>
                                    </td>
                                    <td v-if="not_empty_row['net_monetary_benefit_gbp']">
                                        <template v-if="$i18n.locale == 'fr'">
                                            {{item.net_monetary_benefit_gbp ? item.net_monetary_benefit_gbp : '-'}}
                                        </template>
                                        <template v-else>
                                            {{item.net_monetary_benefit_gbp_en ? item.net_monetary_benefit_gbp_en : '-'}}
                                        </template>
                                    </td>
                                    <td v-if="not_empty_row['net_monetary_loss_gbp']">
                                        <template v-if="$i18n.locale == 'fr'">
                                            {{item.net_monetary_loss_gbp ? item.net_monetary_loss_gbp : '-'}}
                                        </template>
                                        <template v-else>
                                            {{item.net_monetary_loss_gbp_en ? item.net_monetary_loss_gbp_en : '-'}}
                                        </template>
                                    </td>
                                    <td v-if="not_empty_row['smc_comment']">
                                        <div class="expandable textJustify"> 
                                            <span v-if="$i18n.locale == 'fr'">{{item.smc_comment ? item.smc_comment : '-'}}</span>
                                            <span v-else>{{item.smc_comment_en ? item.smc_comment_en : '-'}}</span>
                                        </div>
                                    </td>
                                </tr> 
                            </template>
                        </tbody> 
                    </table> 
                    <!-- EconomicAnalysi end -->

                    <!--Decision du college de la HAS/Decision of the HAS start-->
                    <template v-else-if="key == 'detail_décision_seesp' && item.show">
                        <table class="comparateur2" >
                            <thead>
                                <tr>
                                    <th width="30%" class="first" >{{$t('date_decision_seesp')}}</th>
                                    <th width="40%" >{{$t('object')}}</th>
                                    <th width="30%" class="last" >Date publication SEESP</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{DDMMMYY(data.ceesp.date)}}</td>
                                    <td><LinkUpload :href="'/uploads/Ceesp/'+$route.params.id+'/pdf_1.pdf'" class="pdf" target="_blank" rel="noopener">{{$t('objet_'+data.ceesp.objet)}}</LinkUpload> </td>
                                    <td>{{DDMMMYY(data.ceesp.date_pdf_1)}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="comparateur2 border-top-gray text-left" >
                            <tbody class="noLineLR">
                                <tr>
                                    <td><strong>{{$t('iab_requested')}}</strong></td>
                                    <td>{{$t('yes_no_'+data.ceesp.iab_requested)}}</td>
                                </tr>
                                <tr>
                                    <td><strong>{{$t('incidence_healtcare')}}</strong></td>
                                    <td>{{$t('yes_no_'+data.ceesp.incidence_healtcare)}}</td>
                                </tr> 
                                <tr>
                                    <td><strong>{{$t('turnover_20')}}</strong></td>
                                    <td>{{$t('yes_no_'+data.ceesp.turnover_20)}}</td>
                                </tr> 
                            </tbody>
                        </table>
                        <table class="comparateur2" >
                            <thead>
                                <tr>
                                    <th width="30%" class="first" >{{$t('impact_significatif')}}</th>
                                    <th width="70%" class="last" >{{$t('conclusion_title')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{$t('yes_no_'+data.ceesp.impact_significatif)}}</td>
                                    <td>{{$t('conclusion_'+data.ceesp.conclusion)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <!--Decision du college de la HAS/Decision of the HAS end-->  

                    <!--Contexte de l'étude start-->   
                    <table class="comparateur2" v-else-if="key == 'detail_medico_analyse_support' && item.show" > 
                        <thead>
                            <tr>
                                <th style="width:33%;">{{$t('medico_analyse_support')}}</th>
                                <th style="width:33%;">{{$t('asmr_claim_request')}}</th>
                                <th style="width:33%;">{{$t('asmr_obtain')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{data.ceesp.medico_analyse_support && data.ceesp.medico_analyse_support != '0' ? data.ceesp.medico_analyse_support : '-'}}</td>
                                <td>{{data.ceesp.asmr_claim_request && data.ceesp.asmr_claim_request != '0' ? data.ceesp.asmr_claim_request : '-'}}</td>
                                <td>{{data.ceesp.asmr_obtain && data.ceesp.asmr_obtain != '0' ? data.ceesp.asmr_obtain : '-'}}</td>
                            </tr> 
                        </tbody>
                    </table> 
                    <!--Contexte de l'étude end-->

                    <!--detail_indication_under_review_original start--> 
                    <table class="comparateur2" v-else-if="key == 'detail_indication_under_review_original' && item.show" > 
                        <thead>
                            <tr>
                                <th style="width:33%;">{{$t('approve_indicationfr')}}</th>
                                <th style="width:33%;">{{$t('subject_economic_evaluationfr')}}</th>
                                <th style="width:33%;">{{$t('same_amm_ma')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="text-align: left;">
                                    <span v-if="$i18n.locale == 'fr'" v-html="data.ceesp['approve_indicationfr']"></span>
                                    <span v-else v-html="data.ceesp['approve_indicationen']"></span>
                                </td>
                                <td style="text-align: left;">
                                    <span v-if="$i18n.locale == 'fr'" v-html="data.ceesp['subject_economic_evaluationfr'] ? data.ceesp['subject_economic_evaluationfr'] : '-'"></span>
                                    <span v-else v-html="data.ceesp['subject_economic_evaluationen'] ? data.ceesp['subject_economic_evaluationen'] : '-'"></span>
                                </td>
                                <td>{{data.ceesp.same_amm_ma && data.ceesp.same_amm_ma != 0 ? $t(data.ceesp.same_amm_ma) : '-'}}</td>
                            </tr> 
                        </tbody>
                    </table>
                    <!--detail_indication_under_review_original end-->  

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->

                    <!--MA spec start-->
                    <table class="comparateur2" v-else-if="key == 'detail_MaSpac' && item.show" > 
                        <tr colspan="3">
                            <td class="bold border-top-gray"><strong>{{$t('TRS_AMM')}}</strong></td>
                            <td class="border-top-gray">{{$t('ceesp_type_amm_'+data.ceesp.ceesp_type_amm)}}</td>
                        </tr>
                    </table>
                    <!--MA spec end-->

                    <!--budget impact start-->  
                    <template v-else-if="key == 'detail_budget_impact' && item.show" >
                        <table class="comparateur2" v-if="data.ceesp.budget_impact || data.ccesp.total_cost || data.ceesp.number_patient" > 
                        <thead>
                                <tr>
                                    <th class="first" style="width:33%;" v-if="data.ceesp.budget_impact">{{$t('budget_impact')}}</th>
                                    <th style="width:33%;" v-if="data.ceesp.total_cost">{{$t('total_cost')}}</th>
                                    <th class="last" style="width:33%;" v-if="data.ceesp.number_patient">{{$t('number_patient')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-if="data.ceesp.budget_impact">{{$t(data.ceesp.budget_impact)}}</td>
                                    <td v-if="data.ceesp.total_cost">{{data.ceesp.total_cost}}</td>
                                    <td v-if="data.ceesp.number_patient">{{data.ceesp.number_patient}}</td>
                                </tr> 
                            </tbody>
                        </table>
                        <table class="comparateur2" v-if="data.ceesp.budget_impac_1_year || data.ceesp.budget_impac_5_year"> 
                            <thead>
                                <tr>
                                    <th class="first" style="width:50%;" v-if="data.ceesp.budget_impac_1_year">{{$t('budget_impac_1_year')}}</th>
                                    <th class="last" style="width:50%;" v-if="data.ceesp.budget_impac_5_year">{{$t('budget_impac_5_year')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{data.ceesp.budget_impac_1_year}}</td>
                                    <td>{{data.ceesp.budget_impac_5_year}}</td>
                                </tr> 
                            </tbody>
                        </table>
                    </template>
                    <!--budget impact end-->

                    <!--RDCR start-->
                    <table class="comparateur2" v-else-if="key == 'detail_CeespRdcrIcer' && item.show" > 
                        <thead>
                            <tr>
                                <th class="first" style="width:15%;">{{$t('variable')}}</th>
                                <th style="width:15%;">{{$t('icer_basecase')}}</th>
                                <th style="width:15%;">{{$t('icer_basecase_per_year')}}</th>
                                <th style="width:15%;">{{$t('icer_lower')}}</th>
                                <th style="width:15%;">{{$t('icer_upper')}}</th>
                                <th class="last" style="width:25%;">{{$t('probabilite_50_80')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value_rdcr_icer ,key_rdcr_icer) in data.ceesp.ceesp_rdcr_icer" :key="'ceesp_rdcr_icer'+key_rdcr_icer">
                                <td><span v-html="check_empty($i18n.locale == 'fr' ? value_rdcr_icer['variablefr'] : value_rdcr_icer['variableen'])"></span></td>
                                <td><span v-html="check_empty($i18n.locale == 'fr' ? value_rdcr_icer['icer_basecasefr'] : value_rdcr_icer['icer_basecaseen'])"></span></td>
                                <td><span v-html="check_empty($i18n.locale == 'fr' ? value_rdcr_icer['icer_basecase_per_yearfr'] : value_rdcr_icer['icer_basecase_per_yearen'])"></span></td>
                                <td><span v-html="check_empty($i18n.locale == 'fr' ? value_rdcr_icer['icer_upperfr'] : value_rdcr_icer['icer_upperen'])"></span></td>
                                <td><span v-html="check_empty($i18n.locale == 'fr' ? value_rdcr_icer['icer_lowerfr'] : value_rdcr_icer['icer_loweren'])"></span></td>
                                <td><span v-html="check_empty($i18n.locale == 'fr' ? value_rdcr_icer['probabilite_50_80_fr'] : value_rdcr_icer['probabilite_50_80_en'])"></span></td>
                            </tr> 
                        </tbody>
                    </table>
                    <!--RDCR end-->  

                    <!-- Analyse de sensibilité start-->
                    <table class="comparateur2" v-else-if="key == 'detail_CeespAnalyseSensibilitie' && item.show" > 
                        <thead>
                            <tr>
                                <th class="first" style="width:33%;">{{$t('sensitive_analysis')}}</th>
                                <th style="width:33%;">{{$t('high_uncertainty_paramater')}}</th>
                                <th class="last" style="width:33%;">{{$t('conclusion_sensitivity_analysis')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value_analyse_sensibilitie , key_analyse_sensibilitie) in data.ceesp.ceesp_analyse_sensibilitie" :key="'ceesp_analyse_sensibilitie'+key_analyse_sensibilitie">
                                <td><span class="text-left" v-html="$i18n.locale == 'fr' ? value_analyse_sensibilitie['sensitive_analysisfr'] : value_analyse_sensibilitie['sensitive_analysisen']"></span></td>
                                <td><span class="text-left" v-html="$i18n.locale == 'fr' ? value_analyse_sensibilitie['high_uncertainty_paramaterfr'] : value_analyse_sensibilitie['high_uncertainty_paramateren']"></span></td>
                                <td><span class="text-left" v-html="$i18n.locale == 'fr' ? value_analyse_sensibilitie['conclusion_sensitivity_analysisfr'] : value_analyse_sensibilitie['conclusion_sensitivity_analysisen']"></span></td>
                            </tr> 
                        </tbody>
                    </table>
                    <!-- Analyse de sensibilité end--> 

                    <!-- Conformité de l'évaluation économique aux recommandations méthodologiques de la HAS start-->  
                    <template v-else-if="key == 'detail_appreciation' && item.show" >
                        <table class="comparateur2 text-left" v-if="$i18n.locale == 'fr' && data.ceesp['conclusionfr'] ||
                                                                    $i18n.locale != 'fr' && data.ceesp['conclusionen']">
                            <thead>
                                <tr>
                                    <th>{{$t('conclusion_title')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <span v-if="$i18n.locale == 'fr'" v-html="data.ceesp['conclusionfr']"></span>
                                        <span v-else v-html="data.ceesp['conclusionen']"></span>
                                    </td>
                                </tr> 
                            </tbody>
                        </table> 
                        <table class="comparateur2 text-left" v-if="data.ceesp.additional_data && data.ceesp.additional_data != 0 || 
                                                                    $i18n.locale == 'fr' && data.ceesp['additional_data_textfr'] ||
                                                                    $i18n.locale != 'fr' && data.ceesp['additional_data_texten']">
                            <thead>
                                <tr>
                                    <th colspan="2">{{$t('additional_data')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="width: 30%;">{{$t(data.ceesp.additional_data)}}</td>
                                    <td>
                                        <span v-if="$i18n.locale == 'fr'" v-html="data.ceesp['additional_data_textfr']"></span>
                                        <span v-else v-html="data.ceesp['additional_data_texten']"></span>
                                    </td>
                                </tr> 
                            </tbody>
                        </table> 
                    </template>
                    <!-- Conformité de l'évaluation économique aux recommandations méthodologiques de la HAS end--> 

                    <!-- Comparateur start-->  
                    <table class="comparateur2" v-else-if="key == 'detail_active_comparator' && item.show" >  
                        <thead>
                            <tr>
                                <th class="first" style="width:30%;">{{$t('active_comparator')}}</th>
                                <th style="width:35%;">{{$t('CeespComparatorName')}}</th>
                                <th class="last" style="width:35%;">{{$t('compatator_relevancy')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{data.ceesp.active_comparator}}</td>
                                <td v-html="display_array_to_text(data.ceesp.ceesp_comparator_name, $i18n.locale == 'fr' ? 'titlefr' : 'titleen', '<br />')">
                                </td>
                                <td>{{check_empty(data.ceesp.compatator_relevancy)}}</td>
                            </tr> 
                        </tbody>
                    </table> 
                    <!-- Comparateur end-->

                    <!-- Population start-->  
                    <table class="comparateur2 border-top-gray text-left" v-else-if="key == 'detail_poppulation_analyse' && item.show" > 
                        <tbody>
                            <tr>
                                <td width="33%">{{$t('poppulation_analyse')}} : </td>
                                <td>
                                    <span v-html="check_empty($i18n.locale == 'fr'? data.ceesp['poppulation_analysefr'] : data.ceesp['poppulation_analyseen'])"></span>
                                </td>
                            </tr>                                    
                            <tr>
                                <td>{{$t('index_treatment')}} :</td>
                                <td>
                                    <span v-html="check_empty($i18n.locale == 'fr' ? data.ceesp['index_treatmentfr'] : data.ceesp['index_treatmenten'])"></span>
                                </td>
                            </tr> 
                            <tr>
                                <td>{{$t('utility_data')}} :</td>
                                <td>
                                    <span v-html="check_empty($i18n.locale == 'fr' ? data.ceesp['utility_datafr'] : data.ceesp['utility_dataen'])"></span>
                                </td>
                            </tr> 
                            <tr>
                                <td>{{$t('cost_type')}} :</td>
                                <td><span v-html="check_empty($i18n.locale == 'fr' ? data.ceesp['cost_typefr'] : data.ceesp['cost_typeen'])"></span></td>
                            </tr> 
                        </tbody>
                    </table> 
                     <!-- Population end--> 

                    <!-- Avis / Guidance start-->  
                    <template v-else-if="key == 'detail_context_medico_eoconomica_study' && item.show" >
                        <table class="comparateur2"  v-if="$i18n.locale == 'fr' && data.ceesp['context_medico_eoconomica_studyfr'] ||
                                                                                    $i18n.locale != 'fr' && data.ceesp['context_medico_eoconomica_studyen']"> 
                            <thead>
                                <tr>
                                    <th>{{$t('context_medico_eoconomica_study')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">
                                        <span v-if="$i18n.locale == 'fr'" v-html="data.ceesp['context_medico_eoconomica_studyfr']"></span>
                                        <span v-else v-html="data.ceesp['context_medico_eoconomica_studyen']"></span>
                                    </td>
                                </tr> 
                            </tbody>
                        </table> 
                        <table class="comparateur2"  v-if="$i18n.locale == 'fr' && data.ceesp['compliance_hasfr'] ||
                                                                                    $i18n.locale != 'fr' && data.ceesp['compliance_hasen']"> 
                            <thead>
                                <tr>
                                    <th>{{$t('compliance_has')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">
                                        <span v-if="$i18n.locale == 'fr'" v-html="data.ceesp['compliance_hasfr']"></span>
                                        <span v-else v-html="data.ceesp['compliance_hasen']"></span>
                                    </td>
                                </tr> 
                            </tbody>
                        </table> 
                        <table class="comparateur2"  v-if="$i18n.locale == 'fr' && data.ceesp['consclusion_ceespfr'] || 
                                                                                    $i18n.locale != 'fr' && data.ceesp['consclusion_ceespen']"> 
                            <thead>
                                <tr>
                                    <th>{{$t('consclusion_ceesp')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">
                                        <span v-if="$i18n.locale == 'fr'" v-html="data.ceesp['consclusion_ceespfr']"></span>
                                        <span v-else v-html="data.ceesp['consclusion_ceespen']"></span>
                                    </td>
                                </tr> 
                            </tbody>
                        </table> 
                        <table class="comparateur2"  v-if="$i18n.locale == 'fr' && data.ceesp['additional_datafr'] || 
                                                                                    $i18n.locale != 'fr' && data.ceesp['additional_dataen']"> 
                            <thead>
                                <tr>
                                    <th>{{$t('additional_data')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">
                                        <span v-if="$i18n.locale == 'fr'" v-html="data.ceesp['additional_datafr']"></span>
                                        <span v-else v-html="data.ceesp['additional_dataen']"></span>
                                    </td>
                                </tr> 
                            </tbody>
                        </table> 
                    </template>
                    <!-- Avis / Guidance end--> 

                    <!-- 'sub_detail/conclusion.ctp' -->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />  

                    <!--EssaisClinique start-->
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                    <!--EssaisClinique end-->   

                    <!-- 'sub_detail/evaluation_economic.ctp'  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.ceesp.evaluation_economic" />

                    <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />

                    <!-- Ceesp Réserves start-->
                    <table class="comparateur2" v-else-if="key == 'detail_Reserves' && item.show" > 
                        <thead>
                            <tr>
                                <th>{{$t('Libellé de la réserve')}}</th>
                                <th style="width:10%">-</th>
                                <th style="width:10%">+</th>
                                <th style="width:10%">++</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(valueReserveMain, keyReserveMain) in array_reserve">
                                <template v-for="(valueReserve, keyReserve) in valueReserveMain['sub']" :key="keyReserveMain+'_'+keyReserve">
                                    <tr>
                                        <td class="text-left" colspan="4"><strong>{{$t('ceesp.'+keyReserve)}}</strong></td>
                                    </tr> 
                                    <template v-if="Object.keys(order_efficience(valueReserve)).length > 0">
                                        <template v-for="(valueReserve2, key2) in order_efficience(valueReserve)" :key="keyReserveMain+'_'+keyReserve+'_'+key2">
                                            <tr> 
                                                <td class="text-left" colspan="4"><strong> {{$t('ceesp.'+key2)}}</strong></td>                                                
                                            </tr>                                      
                                            <template v-for="(valueReserve3, key3) in valueReserve2">
                                                <tr v-for="(valueReserve4, key4) in valueReserve3" :key="keyReserveMain+'_'+keyReserve+'_'+key2+'_'+key3+'_'+key4+'_'">
                                                    <td class="text-left"> <span v-html="$i18n.locale == 'fr' ? valueReserve4['text'] : valueReserve4['text_en']"></span></td>
                                                    <td class="text-center"> {{valueReserve4['item'] == "-" ? "-" : "" }}</td>
                                                    <td class="text-center"> {{valueReserve4['item'] == "+" ? "+" : "" }}</td>
                                                    <td class="text-center"> {{valueReserve4['item'] == "++" ? "++" : ""}}</td>
                                                </tr>
                                            </template>
                                        </template>
                                            
                                    </template>
                                    <template v-else>
                                        <tr :key="keyReserve+'__'+key2" v-for="(valueReserve2, key2) in valueReserve">
                                            <td class="text-left"><span v-html="$i18n.locale == 'fr' ? valueReserve2['text'] : valueReserve2['text_en']"></span></td>
                                            <td class="text-center"> {{valueReserve2['item'] == "-" ? "-" : "" }}</td>
                                            <td class="text-center"> {{valueReserve2['item'] == "+" ? "+" : "" }}</td>
                                            <td class="text-center"> {{valueReserve2['item'] == "++" ? "++" : ""}}</td>
                                        </tr>
                                    </template>
                                    
                                </template>
                            </template>
                        </tbody>
                    </table>
                    <!-- Ceesp Réserves end-->

                    <!-- Key Document start -->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>
                    <!-- Key Document end -->
                    
                    <!-- Section Detail End -->

                </div>
            </template>

            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
            
        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LinkUpload from '../LinkUpload.vue'
import LeftSection from '../LeftSection.vue'
import TreatmentLine from '../treatment_line.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import KeyDocument from '../key_document.vue'
import InformationGeneral from '../InformationGeneral.vue'
import { DDMMMYY, check_empty, display_array_to_text, check_detail_conclusion, key_documents} from '../../../utils'
export default {
    name: 'ceesp',
    components : {
        LinkUpload,
        LeftSection,
        TreatmentLine,
        EvaluationEconomic,
        KeyDocument,
        EssaisClinique,
        EconomicEvaluation,
        Conclusion,
        LinkAgency,
        InformationGeneral
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_information_general : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_indication_under_review_original : {'title' : 'Indication_scope', 'show' : true, 'enable' : false, 'orange_text':false },
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_poppulation_analyse : {'title' : 'population_population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_summary : {'title' : 'Summary', 'show' : true, 'enable' : false, 'orange_text':true}, 
                detail_Reserves : {'title' : 'Réserves', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_décision_seesp : {'title' : 'decision_has', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_medico_analyse_support :  {'title' : 'ceesp.contexte', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_appreciation : {'title' : 'conformite_evaluation', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_context_medico_eoconomica_study : {'title' : 'avis_guidance', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_budget_impact : {'title' : 'analyse_budgetaire', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },

                detail_EconomicAnalysis : {'title' : 'Economic analysis', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_MaSpac : {'title' : 'information generale trs', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_CeespRdcrIcer : {'title' : 'CeespRdcrIcer', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_CeespAnalyseSensibilitie : {'title' : 'analyse_sensibilite', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_model_type : {'title' : 'detail_etude', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_active_comparator : {'title' : 'comparateur', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },
            },
                
            not_empty_row : {
                'source_of_analysis' : false,
                'patient_population' : false,
                'comparator' : false,
                'icer_gbp' : false,
                'icer_range_gbp' : false,
                'icur_gbp' : false,
                'icur_range_gbp' : false,
                'cost_savings_gbp' : false,
                'total_cost_gbp' : false,
                'net_monetary_benefit_gbp' : false,
                'net_monetary_loss_gbp' : false,
                'smc_comment' : false
            },
            array_reserve : {},
            
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.ceesp){
               
            if(this.data.ma_date){
                this.list_check_box['detail_information_general'].enable = true
            }
            if(this.data.ceesp.ceesp_titleappreserve.length > 0){ 
                this.list_check_box['detail_summary'].enable = true
            }
            if(this.data.ceesp.evaluation_economic[0] && this.data.ceesp.evaluation_economic[0].evaluation_economic_economic_analysis.length > 0) { 
                this.list_check_box['detail_EconomicAnalysis'].enable = true 
            }
            if(this.data.ceesp.date_avis_guidance) { 
                this.list_check_box['detail_décision_seesp'].enable = true
            }
            if(this.data.ceesp.medico_analyse_support && this.data.ceesp.medico_analyse_support != 0 || 
                this.data.ceesp.asmr_claim_request && this.data.ceesp.asmr_claim_request != 0 || 
                this.data.ceesp.asmr_obtain && this.data.ceesp.asmr_obtain != 0) { 
                this.list_check_box['detail_medico_analyse_support'].enable = true 
            }
            if(this.$i18n.locale == 'fr' && this.data.ceesp['approve_indicationfr'].replace(/(<([^>]+)>)/gi, "") || 
                this.$i18n.locale != 'fr' && this.data.ceesp['approve_indicationen'].replace(/(<([^>]+)>)/gi, "") || 
                this.$i18n.locale == 'fr' && this.data.ceesp['subject_economic_evaluationfr'].replace(/(<([^>]+)>)/gi, "") || 
                this.$i18n.locale != 'fr' && this.data.ceesp['subject_economic_evaluationen'].replace(/(<([^>]+)>)/gi, "") || 
                this.data.ceesp.same_amm_ma && this.data.ceesp.same_amm_ma != 0) { 
                this.list_check_box['detail_indication_under_review_original'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(this.data.ceesp.ceesp_type_amm) { 
                this.list_check_box['detail_MaSpac'].enable = true
            }
            if(this.data.ceesp.budget_impact && this.data.ceesp.budget_impact != 0 || 
                this.data.ceesp.total_cost || this.data.ceesp.number_patient || 
                this.data.ceesp.budget_impac_1_year||  this.data.ceesp.budget_impac_5_year) { 
                this.list_check_box['detail_budget_impact'].enable = true
            }
            if(this.data.ceesp.ceesp_rdcr_icer.length > 0) { 
                this.list_check_box['detail_CeespRdcrIcer'].enable = true 
            }
            if(this.data.ceesp.ceesp_analyse_sensibilitie.length > 0) { 
                this.list_check_box['detail_CeespAnalyseSensibilitie'].enable = true
            }
            if(this.$i18n.locale == 'fr' && this.data.ceesp['conclusionfr'].replace(/(<([^>]+)>)/gi, "") || 
                this.$i18n.locale != 'fr' && this.data.ceesp['conclusionen'].replace(/(<([^>]+)>)/gi, "") ||
                this.data.ceesp.additional_data && this.data.ceesp.additional_data != 0 || 
                this.$i18n.locale == 'fr' && this.data.ceesp['additional_data_textfr'].replace(/(<([^>]+)>)/gi, "") ||
                this.$i18n.locale != 'fr' && this.data.ceesp['additional_data_texten'].replace(/(<([^>]+)>)/gi, "")) { 
                this.list_check_box['detail_appreciation'].enable = true
            }
            if(this.data.ceesp.model_type || 
                this.data.ceesp.cycle_time || 
                this.data.ceesp.type_analyse || 
                this.data.ceesp.perspective || 
                this.data.ceesp.time_horizon){
                    this.list_check_box['detail_model_type'].enable = true
                }
            if((this.data.ceesp.active_comparator && this.data.ceesp.active_comparator != 0) || 
                this.data.ceesp.ceesp_comparator_name.length || this.data.ceesp.compatator_relevancy) { 
                    this.list_check_box['detail_active_comparator'].enable = true
            }
            if(this.$i18n.locale == 'fr' && this.data.ceesp['poppulation_analysefr'].replace(/(<([^>]+)>)/gi, "") || 
                this.$i18n.locale != 'fr' && this.data.ceesp['poppulation_analyseen'].replace(/(<([^>]+)>)/gi, "") ||
                this.$i18n.locale == 'fr' && this.data.ceesp['index_treatmentfr'].replace(/(<([^>]+)>)/gi, "") ||
                this.$i18n.locale != 'fr' && this.data.ceesp['index_treatmenten'].replace(/(<([^>]+)>)/gi, "") ||
                this.$i18n.locale == 'fr' && this.data.ceesp['utility_datafr'].replace(/(<([^>]+)>)/gi, "") ||
                this.$i18n.locale != 'fr' && this.data.ceesp['utility_dataen'].replace(/(<([^>]+)>)/gi, "") ||
                this.$i18n.locale == 'fr' && this.data.ceesp['cost_typefr'].replace(/(<([^>]+)>)/gi, "") ||
                this.$i18n.locale != 'fr' && this.data.ceesp['cost_typeen'].replace(/(<([^>]+)>)/gi, "")) { 
                this.list_check_box['detail_poppulation_analyse'].enable = true
            }
            if(this.$i18n.locale == 'fr' && this.data.ceesp['context_medico_eoconomica_studyfr'].replace(/(<([^>]+)>)/gi, "") || 
                this.$i18n.locale != 'fr' && this.data.ceesp['context_medico_eoconomica_studyen'].replace(/(<([^>]+)>)/gi, "") || 
                this.$i18n.locale == 'fr' && this.data.ceesp['compliance_hasfr'].replace(/(<([^>]+)>)/gi, "") || 
                this.$i18n.locale != 'fr' && this.data.ceesp['compliance_hasen'].replace(/(<([^>]+)>)/gi, "") || 
                this.$i18n.locale == 'fr' && this.data.ceesp['consclusion_ceespfr'].replace(/(<([^>]+)>)/gi, "") || 
                this.$i18n.locale != 'fr' && this.data.ceesp['consclusion_ceespen'].replace(/(<([^>]+)>)/gi, "") || 
                this.$i18n.locale == 'fr' && this.data.ceesp['additional_datafr'].replace(/(<([^>]+)>)/gi, "") || 
                this.$i18n.locale != 'fr' && this.data.ceesp['additional_dataen'].replace(/(<([^>]+)>)/gi, "")) { 
                this.list_check_box['detail_context_medico_eoconomica_study'].enable = true 
            }
            if(this.check_detail_conclusion(this.data, this.$i18n.locale)) { 
                    this.list_check_box['detail_Conclusion'].enable = true
            }
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }
            if(this.data.ceesp.evaluation_economic.length > 0) { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }

            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 

                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false
                this.list_check_box['detail_EconomicAnalysis'].enable = false
            }
            if(this.data.ceesp.ceesp_reserve.length > 0) { 
                this.list_check_box['detail_Reserves'].enable = true
            }
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                    this.list_check_box['detail_key_documents'].enable = true
            }
                
            for(let key in this.data.ceesp.evaluation_economic){
                for(let sub_key in this.data.ceesp.evaluation_economic[key].evaluation_economic_economic_analysis){
                    for(let key_2 in this.not_empty_row){
                        if(!this.not_empty_row[key_2]){
                            if(this.$i18n.locale != 'fr'){
                                this.not_empty_row[key_2] = this.data.ceesp.evaluation_economic[key].evaluation_economic_economic_analysis[sub_key][key_2+'_en'] ? true : false
                            }else{
                                this.not_empty_row[key_2] = this.data.ceesp.evaluation_economic[key].evaluation_economic_economic_analysis[sub_key][key_2] ? true : false
                            }
                        }
                    }
                }
            }
            
            this.sub_data_reserve()
        }
        //this.show_block = ['detail_medico_analyse_support', 'detail_appreciation']
    },
    methods : {
        DDMMMYY,
        check_empty,
        check_detail_conclusion,
        // Ceesp Reserve
        sub_data_reserve(){
            let arr_temp = {}
            let arr_temp2 = {}

            for(let key_data in this.data.ceesp.ceesp_reserve){
                if(this.data.ceesp.ceesp_reserve[key_data].type == 'CeespReserve'){
                    arr_temp[key_data] = this.data.ceesp.ceesp_reserve[key_data]
                }
            }
            for(let key in arr_temp){
                arr_temp2[key] = {'sub' : {}}

                console.log(arr_temp2)

                for(let key_data in this.data.ceesp.ceesp_reserve){
                    if(this.data.ceesp.ceesp_reserve[key_data].parent_id == arr_temp[key].id){
                        let value_type = this.data.ceesp.ceesp_reserve[key_data].type
                        if(!arr_temp2[key]['sub'][value_type]){
                            arr_temp2[key]['sub'][value_type] = {} 
                        }
                        arr_temp2[key]['sub'][value_type][key_data] = this.data.ceesp.ceesp_reserve[key_data]
                        
                        for(let key_data_2 in this.data.ceesp.ceesp_reserve){ 
                            let sub_value_type = this.data.ceesp.ceesp_reserve[key_data_2].type
                            if(!arr_temp2[key]['sub'][value_type][key_data]['sub']){
                                arr_temp2[key]['sub'][value_type][key_data]['sub'] = {}  
                            }

                            if(!arr_temp2[key]['sub'][value_type][key_data]['sub'][sub_value_type]){
                                arr_temp2[key]['sub'][value_type][key_data]['sub'][sub_value_type] = []
                            }
                            
                            
                            if(this.data.ceesp.ceesp_reserve[key_data_2].parent_id == this.data.ceesp.ceesp_reserve[key_data].id){
                                // if(arr_temp2[key]['sub'][value_type][key_data]['sub'][sub_value_type]){
                                arr_temp2[key]['sub'][value_type][key_data]['sub'][sub_value_type].push(this.data.ceesp.ceesp_reserve[key_data_2])
                                // }
                                
                            }
                        }
                    }
                }
                
            }

            this.array_reserve = arr_temp2
        },
        order_efficience(param) {
            // console.log(param)
            let arr_temp = {}
            let arr = [
                'CeespReserveCommentaireGeneral',
                'CeespReservePopulationSimulee',
                'CeespReserveObjectif',
                'CeespReserveChoixStructurant',
                'CeespReserveDonnee',
                'CeespReserveModelisation',
                'CeespReserveMesureEtat',
                'CeespReserveMesureCout',
                'CeespReserveValidation',
                'CeespReserveResultat',
            ]
            for(let key in arr) {
                
                for(let key_param in param){
                    for(let type in param[key_param]['sub']){
                        if(param[key_param]['sub'][type].length > 0 && type == arr[key]){
                            if(!arr_temp[arr[key]]){
                                arr_temp[arr[key]] = []
                            }
                            arr_temp[arr[key]].push(param[key_param]['sub'][type])
                        }
                    }
                }
                
            }
            return arr_temp
        },
        display_array_to_text,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },        			
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }  
    },
}

</script>

<style scoped>
a{
    color: #000;
}
</style>